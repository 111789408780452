// import tinyColor from 'tinycolor2';
// import { getColorScale } from './themeFunctions';

// const warm = getColorScale(['#0e0e0d', '#fff9ed'], 5);
// const cold = getColorScale(['#0d0e0e', '#edf8ff'], 5);
// const neutral = getColorScale(['#080808', '#f4f4f4'], 5);

// const warmOpaque = warm.map((color) => tinyColor(color).setAlpha(0.666).toRgbString());

// const coldOpaque = cold.map((color) => tinyColor(color).setAlpha(0.666).toRgbString());

// const neutralOpaque = neutral.map((color) => tinyColor(color).setAlpha(0.666).toRgbString());

const projectColors = {
  blueBrand: { primary: '#003781' },
  blue: { primary: '#006192' },
  blueBackground: { dark: '#007D8C', medium: '#E6F4F6', light: '#F1F9FA' },
  blueBox: { primary: '#E6F4F6' },
  greenBox: { primary: '#DFEEDE' },
  beigeBox: { primary: '#F5F0E0' },
};

const themeColors = {
  blueBrand: { primary: projectColors.blueBrand.primary },
  blue: { primary: projectColors.blue.primary },
};

export const colors = {
  text: '#414141',
  background: '#FAFAFA',
  primary: themeColors.blue.primary,
  secondary: '#30c',
  muted: '#e6e6e6',
  error: 'rgba(204, 0, 0, 1)',
  project: projectColors,
  modes: themeColors,
};
