import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const IS_BROWSER: boolean = typeof window !== 'undefined';
export const IS_SERVER: boolean = typeof window === 'undefined';
export const IS_IE = IS_BROWSER
  ? !!(window as any).MSInputMethodContext && !!(document as any).documentMode
  : false;

export const SOCIAL_MEDIA_CARD_IMAGE_URL = '/social-card.png';
export const FAVICON_URL = '/favicon.ico';

export const { VERSION } = publicRuntimeConfig as { [key: string]: string };
